import './scss/ag-theme.scss';
import './scss/ag-custom.scss';
import './scss/main.scss';

import TablesManager from './js/TablesManager';
import loadMoreHistoricalTable from './js/MoreHistorical';

document.addEventListener('DOMContentLoaded', () =>
{
    window.ADVFN_TABLES_MANAGER = new TablesManager();

    const tables = document.querySelectorAll('[data-element="table-panel"]');

    if (tables.length > 0)
    {
        tables.forEach((table) =>
        {
            const tableData = JSON.parse(atob(table.getAttribute('data-options')));
            window.ADVFN_TABLES_MANAGER.addTable(table, tableData);
        });
    }

    // More historical Table (if present)
    const moreHistoricalTable = document.getElementById('table_more_historical');
    if (moreHistoricalTable)
    {
        loadMoreHistoricalTable(moreHistoricalTable);
    }
});
