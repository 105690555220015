import translations from './translations.json';
import BaseTranslationService from '../../../_common/elements/translator/js/BaseTranslationService';

class TranslationService extends BaseTranslationService
{
    constructor(locale)
    {
        super();
        this.setLangFile(translations);
        this.setLocale(locale);
    }

    getTranslations()
    {
        const translationObject = {};

        if (translations[this.locale])
        {
            return translations[this.locale];
        }
        return translationObject;
    }
}

export default TranslationService;
