const DEFAULT_LOCALE = 'en_GB';

const FALLBACK_LOCALES = {
    en_CA: ['en_US', 'en_GB'],
    en_AU: ['en_US', 'en_GB'],
    // Add other specific fallbacks for each language as needed
    // Default fallback is en_GB
};

class BaseTranslationService
{
    setLocale(locale)
    {
        this.locale = this.getValidLocale(locale);
    }

    setLangFile(file)
    {
        this.lang = file;
    }

    getLangFile()
    {
        return this.lang ? this.lang : null;
    }

    getResource()
    {
        return this.lang[this.locale];
    }

    getValidLocale(locale)
    {
        const localesToCheck = [locale, ...(FALLBACK_LOCALES[locale] || [])];
        const validLocale = localesToCheck.find((fallbackLocale) => (this.lang).hasOwnProperty(fallbackLocale));
        return validLocale || DEFAULT_LOCALE;
    }

    getLocale()
    {
        return this.locale ? this.locale : DEFAULT_LOCALE;
    }

    getTranslation(txt)
    {
        if ((this.lang[this.locale]).hasOwnProperty(txt))
        {
            return this.lang[this.locale][txt];
        }
        if ((this.lang[this.locale].general).hasOwnProperty(txt))
        {
            return this.lang[this.locale].general[txt];
        }

        return txt;
    }

}

export default BaseTranslationService;
