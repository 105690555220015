function setupCellRenderers(colDef)
{
    if (colDef.cellRenderer === 'linkCellRenderer')
    {
        colDef.cellRenderer = (params) =>
        {
            const link = document.createElement('a');
            link.setAttribute('href', params.data.url);
            link.innerHTML = params.value;
            return link;
        };
    }

    if (colDef.cellRenderer === 'linkInternalCellRenderer')
    {
        colDef.cellRenderer = (params) =>
        {
            const link = document.createElement('a');
            link.setAttribute('href', params.data.url);
            link.setAttribute('target', '_blank');
            link.setAttribute('rel', 'nofollow noopener noreferrer');
            link.innerHTML = params.value;
            return link;
        };
    }

    if (colDef.cellRenderer === 'imageNameCellRenderer')
    {
        colDef.cellRenderer = (params) =>
        {
            const img = document.createElement('img');
            const name = document.createElement('span');
            img.setAttribute('src', `https://advfn.com${params.data.logo}`);
            img.setAttribute('alt', params.value);
            img.setAttribute('title', params.value);
            name.innerHTML = params.value;
            const div = document.createElement('div');
            div.classList.add('image-name-cell');
            div.appendChild(img);
            div.appendChild(name);

            return div;
        };
    }
}

export default setupCellRenderers;
