import axios from 'axios';

import
{
    easepick, RangePlugin, LockPlugin, PresetPlugin, AmpPlugin,
} from '@easepick/bundle';
import TranslationService from '../../../../_default/stock-market/js/TranslationService';

const loadMoreHistoricalTable = (moreHistoricalTable) =>
{
    const tableId = moreHistoricalTable.getAttribute('id');
    const datepicker = document.getElementById('datepicker');

    let presetFrom = moreHistoricalTable.getAttribute('data-from') * 1000;
    const presetTo = moreHistoricalTable.getAttribute('data-to') * 1000;
    const symbol = moreHistoricalTable.getAttribute('data-symbol');

    const API_ENDPOINT = '/common/api/histo/GetHistoricalData';
    const now = new Date();
    const nowUTC = parseInt(now.getTime() / 1000, 10) + now.getTimezoneOffset() * 60;

    const fourteenDayAgo = nowUTC - 14 * 24 * 60 * 60;
    const threeMonthsAgo = nowUTC - 90 * 24 * 60 * 60;
    const yearAgo = nowUTC - 365 * 24 * 60 * 60;
    const threeYearsAgo = nowUTC - 3 * 365 * 24 * 60 * 60;
    const fiveYearsAgo = nowUTC - 5 * 365 * 24 * 60 * 60;

    const agLoading = moreHistoricalTable.querySelector('.ag-overlay');
    const overlayWrapper = agLoading.querySelector('.ag-overlay-wrapper');
    const agBody = moreHistoricalTable.querySelector('.ag-body');
    const agPagingPanel = moreHistoricalTable.querySelector('.ag-paging-panel');

    // eslint-disable-next-line no-undef
    const translationService = new TranslationService(ADVFN.LOCALE);
    const translationServiceData = translationService.getTranslations();
    const translationToday = translationServiceData.today;
    const translationSevenD = translationServiceData.seven_d;
    const translationThirtyD = translationServiceData.thirty_d;
    const translationThreeM = translationServiceData.three_m;
    const translationOneY = translationServiceData.one_y;
    const translationThreeY = translationServiceData.three_y;
    const translationFiveY = translationServiceData.five_y;
    const translationCancel = translationServiceData.cancel;
    const translationApply = translationServiceData.apply;

    // eslint-disable-next-line no-undef
    let locale = ADVFN.LOCALE;
    locale = locale.replace('_', '-');

    const defaultDateRanges = {
        INTRADAY: {
            from: fourteenDayAgo,
            showDateRange: false,
        },
        DAILY: {
            from: threeMonthsAgo,
            showDateRange: true,
        },
        WEEKLY: {
            from: yearAgo,
            showDateRange: true,
        },
        MONTHLY: {
            from: threeYearsAgo,
            showDateRange: true,
        },
        YEARLY: {
            from: fiveYearsAgo,
            showDateRange: true,
        },
    };

    const fetchAndDisplayData = (endpoint) =>
    {
        agBody.classList.add('hidden');
        agPagingPanel.classList.add('ag-hidden');
        agLoading.classList.add('ag-loading');
        agLoading.classList.remove('ag-hidden');
        overlayWrapper.innerHTML = `
            <span class="ag-overlay-loading-text">
                <i class="fas fa-spinner fa-pulse"></i> Loading data...
            </span>
        `;

        return new Promise((resolve, reject) =>
        {
            axios.get(endpoint)
                .then(({ status, data }) =>
                {
                    if (status === 200)
                    {
                        if (data.status === 'success')
                        {
                            if (data.result.rows)
                            {
                                window.ADVFN_TABLES_MANAGER.updateTable(tableId, data.result.rows);
                                window.ADVFN_TABLES_MANAGER.refreshColumns(tableId);
                                resolve();
                            }
                        }
                    }
                })
                .catch((error) => reject(error))
                .finally(() =>
                {
                    agBody.classList.remove('hidden');
                    agPagingPanel.classList.remove('ag-hidden');
                    agLoading.classList.remove('ag-loading');
                    agLoading.classList.add('ag-hidden');
                    overlayWrapper.innerHTML = '';
                });
        });
    };

    const lightMode = document.querySelector('body').attributes.getNamedItem('data-theme').value === 'light';
    const darkMode = document.querySelector('body').attributes.getNamedItem('data-theme').value === 'dark';
    let easepickCustom = '';
    if (lightMode)
    {
        easepickCustom = '/common/elements/historical/src/css/easepick-custom.css';
    }
    else if (darkMode)
    {
        easepickCustom = '/common/elements/historical/src/css/easepick-custom-dark.css';
    }

    // eslint-disable-next-line new-cap
    new easepick.create({
        element: datepicker,
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/core@1.2.1/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/range-plugin@1.2.1/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/lock-plugin@1.2.1/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/preset-plugin@1.2.1/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/amp-plugin@1.2.1/dist/index.css',
            easepickCustom,
        ],
        zIndex: 10,
        autoApply: false,
        locale: {
            cancel: translationCancel,
            apply: translationApply,
        },
        lang: locale,
        plugins: [RangePlugin, LockPlugin, PresetPlugin, AmpPlugin],
        LockPlugin: {
            maxDate: new Date(),
        },
        AmpPlugin: {
            dropdown: {
                minYear: 1990,
                months: true,
                years: true,
            },
            resetButton: true,
            darkMode: false,
        },
        PresetPlugin: {
            customPreset: {
                [translationToday]: [now, now],
                [translationSevenD]: [new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000), now],
                [translationThirtyD]: [new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000), now],
                [translationThreeM]: [new Date(now.getFullYear(), now.getMonth() - 3, 1), now],
                [translationOneY]: [new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()), now],
                [translationThreeY]: [new Date(now.getFullYear() - 3, now.getMonth(), now.getDate()), now],
                [translationFiveY]: [new Date(now.getFullYear() - 5, now.getMonth(), now.getDate()), now],
            },
            position: 'left',
        },
        setup(picker)
        {
            // Selecting the date range
            picker.on('select', (e) =>
            {
                let { start, end } = e.detail;

                if (start === null)
                {
                    start = presetFrom;
                }

                if (end === null)
                {
                    end = presetTo;
                }

                let startTimestamp = Math.floor(new Date(start).getTime() / 1000);
                const endTimestamp = Math.floor(new Date(end).getTime() / 1000);

                const frequency = moreHistoricalTable.getAttribute('data-frequency');
                if (frequency === 'INTRADAY')
                {
                    startTimestamp = fourteenDayAgo;
                }

                moreHistoricalTable.setAttribute('data-from', startTimestamp);
                moreHistoricalTable.setAttribute('data-to', endTimestamp);

                fetchAndDisplayData(`${API_ENDPOINT}?symbol=${symbol}&frequency=${frequency}&from=${startTimestamp}&to=${endTimestamp}`)
                    .then(() => picker.hide())
                    .catch((error) => console.warn(error));
            });

            // Handling click actions
            picker.on('click', (e) =>
            {
                const { target } = e;
                if (target.classList.contains('preset-button'))
                {
                    if (target.textContent === 'Last month')
                    {
                        const timestamp = parseInt(target.getAttribute('data-end'), 10);
                        const date = new Date(timestamp);
                        if (!Number.isNaN(date.getTime()))
                        {
                            const formattedDate = date.toISOString().slice(0, 10);
                            picker.gotoDate(formattedDate);
                        }
                    }
                    else
                    {
                        picker.gotoDate(target.getAttribute('data-end'));
                    }
                }
                if (picker.isCancelButton(target))
                {
                    setTimeout(() =>
                    {
                        picker.hide();
                    }, 0);
                }
            });

            // Handling clear action
            picker.on('clear', () =>
            {
                picker.trigger('select', { start: null, end: null });
                picker.gotoDate(presetTo);
            });
        },
    });

    const dropdownWrapper = document.querySelector('#time-frame-dropdown');
    const dropdownBtn = dropdownWrapper.querySelector('.dropdown__button');
    const dropdownList = dropdownWrapper.querySelector('.dropdown__list');
    const dropdownItems = dropdownList.querySelectorAll('.dropdown__list-item');
    const dropdownInput = dropdownWrapper.querySelector('.dropdown__input_hidden');

    dropdownBtn.addEventListener('click', () =>
    {
        dropdownList.classList.toggle('dropdown__list_visible');
        dropdownBtn.classList.toggle('dropdown__button_active');
    });

    dropdownItems.forEach((listItem) =>
    {
        listItem.addEventListener('click', (e) =>
        {
            dropdownItems.forEach((el) =>
            {
                el.classList.remove('dropdown__list-item_active');
            });

            e.target.classList.add('dropdown__list-item_active');
            dropdownBtn.innerText = listItem.innerText;
            dropdownInput.value = listItem.dataset.value;
            dropdownList.classList.remove('dropdown__list_visible');

            let from = moreHistoricalTable.getAttribute('data-from');
            let to = moreHistoricalTable.getAttribute('data-to');

            if (dropdownInput.value in defaultDateRanges)
            {
                const selectedRange = defaultDateRanges[dropdownInput.value];

                if (!datepicker.value)
                {
                    from = selectedRange.from;
                    to = Math.floor(now.getTime() / 1000);
                }

                if (dropdownInput.value === 'INTRADAY')
                {
                    from = fourteenDayAgo;
                    to = Math.floor(now.getTime() / 1000);
                }

                moreHistoricalTable.setAttribute('data-from', from);
                moreHistoricalTable.setAttribute('data-to', to);

                presetFrom = from * 1000;

                const dateRangeElement = document.querySelector('.date-range');
                if (selectedRange.showDateRange)
                {
                    dateRangeElement.classList.remove('hidden');
                }
                else
                {
                    dateRangeElement.classList.add('hidden');
                }
            }

            moreHistoricalTable.setAttribute('data-frequency', dropdownInput.value);

            fetchAndDisplayData(`${API_ENDPOINT}?symbol=${symbol}&frequency=${dropdownInput.value}&from=${from}&to=${to}`)
                .catch((error) => console.warn(error));
        });
    });

    document.addEventListener('click', (e) =>
    {
        if (e.target !== dropdownBtn)
        {
            dropdownBtn.classList.remove('dropdown__button_active');
            dropdownList.classList.remove('dropdown__list_visible');
        }
    });

    document.addEventListener('keydown', (e) =>
    {
        if (e.key === 'Tab' || e.key === 'Escape')
        {
            dropdownBtn.classList.remove('dropdown__button_active');
            dropdownList.classList.remove('dropdown__list_visible');
        }
    });
};

export default loadMoreHistoricalTable;
