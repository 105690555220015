import Formatter from '../../../streaming/src/classes/Formatter';
import TableTranslations from './TableTranslations';

const valueFormatter = new Formatter();

const valueFormatterMapping = {
    emptyValueFormatter: (value) => value || '-',
    percentFormatter: (value, params) =>
    {
        if (value === null || value === undefined || value === '') return value;
        return valueFormatter.formatChange(value, params.data.quoteType, true);
    },
    localeNumberFormatter: (value, params) =>
    {
        if (value === null || value === undefined || value === '')
        {
            return value;
        }
        return valueFormatter.formatVolume(value, params.data.quoteType);
    },
    priceFormatter: (value, params) =>
    {
        if (value === null || value === undefined || value === '') return value;
        return valueFormatter.formatPrice(value, params.data.quoteType);
    },
    dateFormatter: (value) =>
    {
        if (value === null || value === undefined || value === '') return value;
        return valueFormatter.formatDate(value, null, null, null, 'short');
    },
    dateTimeFormatter: (value) =>
    {
        if (value === null || value === undefined || value === '') return value;
        return valueFormatter.formatDate(value, null, null, true, 'short');
    },
    weekPeriodFormatter: (value) =>
    {
        // eslint-disable-next-line no-undef
        const tableTranslations = new TableTranslations(ADVFN.LOCALE);
        const translates = tableTranslations.getColumnTranslations();

        if (value === 1)
        {
            return `1 ${translates.week}`;
        } if (value < 4)
        {
            return `${value}${translates.weeks}`;
        } if (value < 26)
        {
            const months = Math.floor(value / 4);
            return `${months} ${months > 1 ? translates.months : translates.month}`;
        } if (value < 52)
        {
            const months = Math.floor(value / 4);
            return `${months} ${translates.months}`;
        }
        const years = Math.floor(value / 52);
        return `${years} ${years > 1 ? translates.years : translates.year}`;
    },
    timestampHourFormatter: (value) =>
    {
        if (!value) return value;

        const date = new Date(value * 1000);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
};

function setupValueFormatters(colDef)
{
    const valueFormatterNames = colDef.valueFormatter ? colDef.valueFormatter.split(',') : [];
    const valueFormatters = valueFormatterNames.map((name) => valueFormatterMapping[name]).filter((formatter) => formatter);
    if (valueFormatters.length > 0)
    {
        colDef.valueFormatter = (params) =>
        {
            let { value } = params;
            valueFormatters.forEach((formatter) =>
            {
                value = formatter(value, params);
            });
            return value;
        };
    }
}

export default setupValueFormatters;
