/* eslint no-undef: 0 */
class Formatter
{
    /**
     *
     * @param {*} value
     * @param {*} quoteType
     * @param {abbreviated: boolean, currency?: string} options
     * @returns
     */
    formatNumber(value, quoteType, options = {})
    {
        if (typeof LocaleFormat !== 'undefined')
        {
            const { currency, abbreviated } = options;
            const showCurrency = typeof currency !== 'undefined';
            let schema;
            if (abbreviated)
            {
                schema = 'abbreviated';
            }
            return this._localeFormat(value, quoteType, schema, currency, showCurrency);
        }
        return value;
    }

    formatPrice(value, quoteType)
    {
        if (typeof LocaleFormat !== 'undefined')
        {
            return this._localeFormat(value, quoteType, 'price');
        }
        return value;
    }

    formatChange(value, quoteType, percentage = false)
    {
        if (typeof LocaleFormat !== 'undefined')
        {
            if (percentage)
            {
                return this._localeFormat(value, quoteType, 'change', 'percent', true);
            }
            return this._localeFormat(value, quoteType, 'change');
        }
        if (percentage)
        {
            return `${value} %`;
        }
        return value;
    }

    formatVolume(value, quoteType, currency = null, showCurrency = false)
    {
        if (typeof LocaleFormat !== 'undefined')
        {
            if (currency !== null)
            {
                return this._localeFormat(value, quoteType, 'volume', currency, showCurrency);
            }

            return this._localeFormat(value, quoteType, 'volume');
        }
        return value;
    }

    formatTradeSizeWide(value, quoteType, currency = null, showCurrency = false)
    {
        if (typeof LocaleFormat !== 'undefined')
        {
            if (currency !== null)
            {
                return this._localeFormat(value, quoteType, 'vwap', currency, showCurrency);
            }

            return this._localeFormat(value, quoteType, 'vwap');
        }
        return value;
    }

    formatAbbreviated(value, quoteType)
    {
        if (value === null) return '-';

        if (typeof LocaleFormat !== 'undefined')
        {
            return this._localeFormat(value, quoteType, 'abbreviated');
        }
        return value;
    }

    formatDate(timestamp, locale = null, offset = null, displayTime = false, displayTypeDate = null)
    {
        if (Number.isNaN(timestamp)) return '-';

        const date = this._getDateWithOffset(timestamp, offset);

        if (date.toUTCString() === 'Invalid Date') return '-';

        if (locale === null)
        {
            locale = 'en-GB';
        }

        if (displayTypeDate)
        {
            let formattedDate;
            const timeOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric' };
            const dateOptions = this._getDateType(displayTypeDate);

            if (!displayTime)
            {
                formattedDate = date.toLocaleDateString(locale, dateOptions);
            }
            else
            {
                formattedDate = `${date.toLocaleDateString(locale, dateOptions)} ${date.toLocaleTimeString(locale, timeOptions)}`;
            }
            return formattedDate;
        }

        const dateDisplay = date.toLocaleDateString(locale);
        if (!displayTime)
        {
            return dateDisplay;
        }

        return `${dateDisplay} ${this.formatTime(timestamp, locale, offset)}`;
    }

    formatTime(timestamp, offset = null, displaySeconds = false)
    {
        if (Number.isNaN(timestamp)) return '-';

        const date = this._getDateWithOffset(timestamp, offset);

        let hours = date.getUTCHours();
        if (hours < 10)
        {
            hours = `0${hours}`;
        }

        let minutes = date.getUTCMinutes();
        if (minutes < 10)
        {
            minutes = `0${minutes}`;
        }

        if (!displaySeconds)
        {
            return `${hours}:${minutes}`;
        }

        let seconds = date.getUTCSeconds();
        if (seconds < 10)
        {
            seconds = `0${seconds}`;
        }
        return `${hours}:${minutes}:${seconds}`;
    }

    _getDateType(dateType)
    {
        switch (dateType)
        {
            case 'short':
                return { month: 'short', day: 'numeric', year: 'numeric' };
            case 'long':
                return { month: 'long', day: 'numeric', year: 'numeric' };
            default:
                return null;
        }
    }

    _getDateWithOffset(timestamp, offset)
    {
        let newTimestamp = parseInt(timestamp, 10);
        if (offset !== null && !Number.isNaN(offset))
        {
            newTimestamp += parseInt(offset, 10);
        }
        return new Date(newTimestamp * 1000);
    }

    _localeFormat(value, quoteType, schema, currency = '', showCurrency = false)
    {
        return LocaleFormat.formatForInstrumentType(value, quoteType, schema, currency, showCurrency);
    }
}

export default Formatter;
