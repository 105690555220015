import BaseTranslationService from '../../../elements/translator/js/BaseTranslationService';
import langFile from '../lang/translations.json';

class TableTranslations extends BaseTranslationService
{
    constructor(locale)
    {
        super();
        this.setLangFile(langFile);
        this.setLocale(locale);
    }

    getColumnTranslations()
    {
        const translationObject = {};

        if (langFile[this.locale])
        {
            return langFile[this.locale];
        }
        return translationObject;
    }
}

export default TableTranslations;
