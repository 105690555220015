import TableTranslations from './TableTranslations';

// eslint-disable-next-line no-undef
const tableTranslations = new TableTranslations(ADVFN.LOCALE);
const translates = tableTranslations.getColumnTranslations();

const defaultGridOptions = {
    defaultColDef: {
        sortable: true,
        resizable: true,
        autoHeaderHeight: true,
        suppressSizeToFit: false,
        lockVisible: true,
    },
    enableCellTextSelection: true,
    domLayout: 'autoHeight',
    rowHeight: 33,
    suppressMovableColumns: true,
    localeText: translates,
};

function getDefaultOptions(options = {})
{
    const {
        onFirstDataRendered, onGridReady, isResponsive, ...restOptions
    } = options;

    const resizeTable = ({ api }) =>
    {
        if (!isResponsive)
        {
            api.sizeColumnsToFit();
            setTimeout(() =>
            {
                api.sizeColumnsToFit();
            }, 50);
        }
        else
        {
            api.columnModel.autoSizeAllColumns();
        }
    };

    return {
        ...defaultGridOptions,
        onFirstDataRendered: (params) =>
        {
            resizeTable(params);
        },
        onGridReady: (params) =>
        {
            window.addEventListener('resize', () =>
            {
                resizeTable(params);
            });
        },
        ...restOptions,
    };
}

export default getDefaultOptions;
