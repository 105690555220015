/* global agGrid */
import getDefaultOptions from './TableSettings';
import setupValueFormatters from './TableValueFormatters';
import setupCellRenderers from './TableCellRenderers';

export default class TablesManager
{
    constructor()
    {
        this._tablesMap = {};
        this.issetDownloadCsv = false;
    }

    _renderTableHeader(tableElement, tableData)
    {
        const {
            title,
            title_link: titleLink,
            title_link_text: titleLinkText,
        } = tableData;

        if (!title && !titleLink) return;

        const tableHeaderContainer = document.createElement('div');
        tableHeaderContainer.classList.add('table-container-header');
        tableHeaderContainer.classList.add('delight-bordered-container-header');
        tableElement.appendChild(tableHeaderContainer);

        if (title)
        {
            const titleElement = document.createElement('h3');
            titleElement.classList.add('table-title', 'heading-xs', 'text-bold');
            titleElement.innerHTML = title;
            tableHeaderContainer.appendChild(titleElement);
        }

        if (titleLink)
        {
            const titleLinkElement = document.createElement('a');
            titleLinkElement.classList.add('table-title-link', 'delight-more-button', 'ml-a', 'btn-main', 'btn-32', 'btn-accent-tertiary');
            titleLinkElement.setAttribute('href', titleLink);
            titleLinkElement.innerHTML = titleLinkText;
            tableHeaderContainer.appendChild(titleLinkElement);
        }
    }

    _renderTablePanel(tableElement, tableData)
    {
        const { panel_data: panelData } = tableData;

        if (panelData.length === 0)
        {
            return;
        }

        let issetDateRange = true;
        const tablePanelContainer = document.createElement('div');
        tablePanelContainer.classList.add('table-header-panel');
        tableElement.appendChild(tablePanelContainer);

        const leftPartContainer = document.createElement('div');
        leftPartContainer.classList.add('left-part');
        tablePanelContainer.appendChild(leftPartContainer);

        if (tableData.symbol !== undefined && tableData.symbol !== null && tableData.is_responsive === true)
        {
            const symbol = tableData.symbol.slice(tableData.symbol.indexOf('^') + 1);
            const titleContainer = document.createElement('div');
            titleContainer.classList.add('title-table');
            leftPartContainer.appendChild(titleContainer);

            const titleText = document.createElement('div');
            titleText.classList.add('table-header-title');
            titleText.innerHTML = symbol;
            titleContainer.appendChild(titleText);
        }

        if (panelData.time_frame && tableData.is_responsive === false)
        {
            const timeFrameContainer = document.createElement('div');
            timeFrameContainer.classList.add('time-frame');
            leftPartContainer.appendChild(timeFrameContainer);

            if (tableData.is_responsive === false)
            {
                const timeFrameText = document.createElement('div');
                timeFrameText.classList.add('table-header-text', 'body-m');
                timeFrameText.innerHTML = panelData.time_frame.name;
                timeFrameContainer.appendChild(timeFrameText);
            }

            const dropdownContainer = document.createElement('div');
            dropdownContainer.classList.add('dropdown');
            dropdownContainer.setAttribute('id', 'time-frame-dropdown');
            timeFrameContainer.appendChild(dropdownContainer);

            const dropdownButton = document.createElement('button');
            dropdownButton.classList.add('dropdown__button');
            dropdownButton.setAttribute('type', 'button');
            panelData.time_frame.fields.forEach((field) =>
            {
                if (field.selected)
                {
                    dropdownButton.innerHTML = field.name;
                }
            });
            dropdownContainer.appendChild(dropdownButton);

            const dropdownList = document.createElement('ul');
            dropdownList.classList.add('dropdown__list');
            dropdownContainer.appendChild(dropdownList);

            panelData.time_frame.fields.forEach((field) =>
            {
                const dropdownListItem = document.createElement('li');
                dropdownListItem.classList.add('dropdown__list-item');
                dropdownListItem.setAttribute('data-value', field.value);
                dropdownListItem.textContent = field.name;

                if (field.selected)
                {
                    dropdownListItem.classList.add('dropdown__list-item_active');
                }

                dropdownList.appendChild(dropdownListItem);
            });

            const dropdownInputHidden = document.createElement('input');
            dropdownInputHidden.classList.add('dropdown__input_hidden');
            dropdownInputHidden.setAttribute('type', 'text');
            dropdownInputHidden.setAttribute('name', 'select-category');
            dropdownInputHidden.setAttribute('value', '');
            dropdownContainer.appendChild(dropdownInputHidden);

            const dropdownListItems = dropdownList.querySelectorAll('.dropdown__list-item');
            dropdownListItems.forEach((item) =>
            {
                item.addEventListener('click', (event) =>
                {
                    const selectedTimeFrameValue = event.target.getAttribute('data-value');
                    issetDateRange = selectedTimeFrameValue !== 'Daily';
                });
            });
        }

        const rightPartContainer = document.createElement('div');
        rightPartContainer.classList.add('right-part');
        tablePanelContainer.appendChild(rightPartContainer);

        if (panelData.time_frame && tableData.is_responsive === true)
        {
            const timeFrameContainer = document.createElement('div');
            timeFrameContainer.classList.add('time-frame');
            rightPartContainer.appendChild(timeFrameContainer);

            const dropdownContainer = document.createElement('div');
            dropdownContainer.classList.add('dropdown');
            dropdownContainer.setAttribute('id', 'time-frame-dropdown');
            timeFrameContainer.appendChild(dropdownContainer);

            const dropdownButton = document.createElement('button');
            dropdownButton.classList.add('dropdown__button');
            dropdownButton.setAttribute('type', 'button');
            panelData.time_frame.fields.forEach((field) =>
            {
                if (field.selected)
                {
                    dropdownButton.innerHTML = field.name;
                }
            });
            dropdownContainer.appendChild(dropdownButton);

            const dropdownList = document.createElement('ul');
            dropdownList.classList.add('dropdown__list');
            dropdownContainer.appendChild(dropdownList);

            panelData.time_frame.fields.forEach((field) =>
            {
                const dropdownListItem = document.createElement('li');
                dropdownListItem.classList.add('dropdown__list-item');
                dropdownListItem.setAttribute('data-value', field.value);
                dropdownListItem.textContent = field.name;

                if (field.selected)
                {
                    dropdownListItem.classList.add('dropdown__list-item_active');
                }

                dropdownList.appendChild(dropdownListItem);
            });

            const dropdownInputHidden = document.createElement('input');
            dropdownInputHidden.classList.add('dropdown__input_hidden');
            dropdownInputHidden.setAttribute('type', 'text');
            dropdownInputHidden.setAttribute('name', 'select-category');
            dropdownInputHidden.setAttribute('value', '');
            dropdownContainer.appendChild(dropdownInputHidden);

            const dropdownListItems = dropdownList.querySelectorAll('.dropdown__list-item');
            dropdownListItems.forEach((item) =>
            {
                item.addEventListener('click', (event) =>
                {
                    const selectedTimeFrameValue = event.target.getAttribute('data-value');
                    issetDateRange = selectedTimeFrameValue !== 'Daily';
                });
            });
        }

        if (panelData.date_range && issetDateRange)
        {
            const dateRangeContainer = document.createElement('div');
            dateRangeContainer.classList.add('date-range');
            rightPartContainer.appendChild(dateRangeContainer);

            if (tableData.is_responsive === false)
            {
                const dateRangeText = document.createElement('span');
                dateRangeText.classList.add('table-header-text', 'body-m');
                dateRangeText.innerHTML = panelData.date_range.name;
                dateRangeContainer.appendChild(dateRangeText);
            }

            const dateRangeLabel = document.createElement('label');
            dateRangeContainer.appendChild(dateRangeLabel);

            const dateRangeIcon = document.createElement('span');
            dateRangeIcon.classList.add('icon-calendar');
            dateRangeLabel.appendChild(dateRangeIcon);

            const dateRangeInput = document.createElement('input');
            dateRangeInput.setAttribute('type', 'text');
            dateRangeInput.setAttribute('id', 'datepicker');
            dateRangeInput.classList.add('body-m');
            if (tableData.is_responsive === false)
            {
                dateRangeInput.setAttribute('placeholder', panelData.date_range.value);
            }
            dateRangeLabel.appendChild(dateRangeInput);
        }

        if (panelData.download_csv && tableData.is_responsive === false)
        {
            this.issetDownloadCsv = true;
            const downloadCsvContainer = document.createElement('div');
            downloadCsvContainer.classList.add('download-csv');
            rightPartContainer.appendChild(downloadCsvContainer);

            const downloadCsvLink = document.createElement('div');
            downloadCsvLink.classList.add('download-csv-link');
            downloadCsvContainer.appendChild(downloadCsvLink);

            const downloadCsvIcon = document.createElement('span');
            downloadCsvIcon.classList.add('icon-dropdown');
            downloadCsvLink.appendChild(downloadCsvIcon);

            const downloadCsvText = document.createElement('span');
            downloadCsvText.classList.add('body-m', 'text-bold');
            downloadCsvText.innerHTML = panelData.download_csv.name;
            downloadCsvLink.appendChild(downloadCsvText);

            downloadCsvContainer.addEventListener('click', () =>
            {
                if (panelData.download_csv.premium)
                {
                    this.downloadCSV();
                }
                else
                {
                    window.location.href = panelData.download_csv.link;
                }
            });
        }
    }

    /**
     * @param {HTMLElement} tableRootEl
     * @param {*} tableData
     */
    _renderTable(tableRootEl, tableData)
    {
        const tableContainer = document.createElement('div');
        tableContainer.classList.add('delight-bordered-content');
        tableRootEl.appendChild(tableContainer);

        const defaultPagination = {
            paginationPageSize: 25,
        };

        const {
            id, columns, data, pagination, options, is_responsive: isResponsive,
        } = tableData;

        columns.forEach((colDef) =>
        {
            setupValueFormatters(colDef);
            setupCellRenderers(colDef);
        });

        const defaultGridOptions = getDefaultOptions({
            isResponsive,
        });

        if (isResponsive === false)
        {
            this.handleDownloadCsv(tableRootEl, id);
        }

        const gridOptions = {
            ...defaultGridOptions,
            columnDefs: columns,
            rowData: data,
            ...options,
            ...(pagination && pagination.pagination === true && {
                pagination: pagination.pagination,
                paginationPageSize: pagination.paginationPageSize || defaultPagination.paginationPageSize,
                paginationPageSizeSelector: false,
            }),
        };

        const grid = agGrid.createGrid(tableContainer, gridOptions);

        this._tablesMap[id] = {
            el: tableRootEl,
            grid,
            isResponsive,
        };
    }

    /**
     * @param {*} tableRootEl
     * @param {*} tableData
     */
    _updateTable(table, tableData)
    {
        const updateGridOptions = (tableToUpdate) =>
        {
            const tableFrequency = tableToUpdate.el.dataset.frequency;
            const { columnDefs } = tableToUpdate.grid.columnModel;

            if (tableFrequency === 'INTRADAY')
            {
                columnDefs.forEach((colDef) =>
                {
                    if (colDef.field === 'Date')
                    {
                        if (tableToUpdate.isResponsive === false)
                        {
                            colDef.flex = 2;
                        }
                        else
                        {
                            colDef.width = 150;
                        }
                        colDef.valueFormatter = 'dateTimeFormatter';
                        setupValueFormatters(colDef);
                    }
                    else
                        if (tableToUpdate.isResponsive === false)
                        {
                            colDef.flex = 1;
                        }
                        else
                        {
                            colDef.width = 100;
                        }
                });
            }
            else
            {
                columnDefs.forEach((colDef) =>
                {
                    if (tableToUpdate.isResponsive === false)
                    {
                        colDef.flex = 1;
                    }
                    else
                    {
                        colDef.width = 100;
                    }

                    if (colDef.field === 'Date')
                    {
                        colDef.valueFormatter = 'dateFormatter';
                        setupValueFormatters(colDef);
                    }
                });
            }

            const paginationSize = tableToUpdate.grid.paginationPageSize;
            if (tableData.length > paginationSize)
            {
                tableToUpdate.grid.pagination = true;
            }
            else
            {
                tableToUpdate.grid.pagination = false;
            }
        };

        updateGridOptions(table);

        if (table.isResponsive === false)
        {
            this.handleDownloadCsv(table.el, table.el.id);
        }

        table.grid.setGridOption('columnDefs', table.grid.columnModel.columnDefs);
        table.grid.setGridOption('rowData', tableData);
    }

    addTable(tableElement, tableData)
    {
        const defaultFrequency = 'DAILY';
        const currentDate = new Date();
        const today = Math.floor(Date.now() / 1000);
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
        const from = Math.floor(threeMonthsAgo.getTime() / 1000);

        tableElement.setAttribute('data-app', 'delight-table');
        tableElement.classList.add('table-bordered-container');
        tableElement.classList.add('delight-bordered-container');
        tableElement.dataset.symbol = tableData.symbol;
        tableElement.dataset.frequency = defaultFrequency;
        tableElement.dataset.from = from;
        tableElement.dataset.to = today;

        this._renderTableHeader(tableElement, tableData);
        this._renderTablePanel(tableElement, tableData);
        this._renderTable(tableElement, tableData);
    }

    updateTable(tableId, tableData)
    {
        if (this._tablesMap[tableId])
        {
            this._updateTable(this._tablesMap[tableId], tableData);
        }
    }

    refreshColumns(tableId)
    {
        const table = this._tablesMap[tableId];
        if (table)
        {
            if (table.el.offsetWidth > 0)
            {
                table.grid.sizeColumnsToFit();
            }
        }
    }

    addRowEventListener(tableId, event, callback)
    {
        const table = this._tablesMap[tableId];
        if (table)
        {
            table.grid.addEventListener(event, callback);
        }
    }

    getRowData(tableId, rowId)
    {
        const table = this._tablesMap[tableId];
        let data;
        if (table)
        {
            const row = table.grid.getRowNode(rowId);
            if (row)
            {
                data = row.data;
            }
        }
        return data;
    }

    selectRow(tableId, rowId)
    {
        const table = this._tablesMap[tableId];
        if (table)
        {
            table.grid.getRenderedNodes()[rowId].setSelected(true);
        }
    }

    getFormattedDate(date)
    {
        const year = date.getFullYear();
        const month = (`0${date.getMonth() + 1}`).slice(-2);
        const day = (`0${date.getDate()}`).slice(-2);
        return `${year}${month}${day}`;
    }

    handleDownloadCsv(tableRootEl, id)
    {
        if (this.issetDownloadCsv)
        {
            const feedSymbol = tableRootEl.getAttribute('data-symbol');
            this.downloadCsv(id, feedSymbol);
        }
    }

    downloadCsv(tableId, symbol)
    {
        const startTimestampInSeconds = 19700101;
        const currentDate = this.getFormattedDate(new Date());
        let fileName = `datadownload-${startTimestampInSeconds}-${currentDate}`;

        if (symbol !== undefined && symbol !== null)
        {
            symbol = symbol.slice(symbol.indexOf('^') + 1);
            fileName += `-${symbol}`;
        }

        fileName += '.csv';

        const csvExportParams = {
            fileName,
        };

        this.downloadCSV = () =>
        {
            this._tablesMap[tableId].grid.exportDataAsCsv(csvExportParams);
        };
    }
}
